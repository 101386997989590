// We import the CSS which is extracted to its own file by esbuild.
// Remove this line if you add a your own CSS build pipeline (e.g postcss).
// 
// If you want to use Phoenix channels, run `mix help phx.gen.channel`
// to get started and then uncomment the line below.
// import "./user_socket.js"

// You can include dependencies in two ways.
//
// The simplest option is to put them in assets/vendor and
// import them using relative paths:
//
//     import "../vendor/some-package.js"
//
// Alternatively, you can `npm install some-package --prefix assets` and import
// them using a path starting with the package name:
//
//     import "some-package"
//

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html"
// Establish Phoenix Socket and LiveView configuration.
import {Socket} from "phoenix"
import {LiveSocket} from "phoenix_live_view"
import topbar from "../vendor/topbar"
import Croppie from "../vendor/croppie"

let Hooks = {}

Hooks.CropImage = {
  mounted() {
    var el = document.getElementById('vanilla-demo');
    var lv = this;
    const image = document.getElementById('image');

    var vanilla = new Croppie(el, {
        viewport: { width: 500, height: 400 },
        boundary: { width: 550, height: 450 },
        showZoomer: false,
        mouseWheelZoom: false,
        enableZoom: true,
        enableOrientation: true,
        enableResize: true,
    });
    vanilla.bind({
        url: image.src
    });

    document.querySelector('#vanilla-result').addEventListener('click', function (ev) {
      lv.pushEvent("confirm_crop", vanilla.get())
    });
  }
}

Hooks.HoverMenu = {
  mounted() {
    const items = document.querySelectorAll('.menu-item');
    const submenuitems = document.querySelectorAll('.menu-item div');

    submenuitems.forEach(item => {
      item.addEventListener('mouseover', function handleClick(event) {
        item.style.display = "flex";
      });

      item.addEventListener('mouseleave', function handleClick(event) {
        setTimeout(() => {
          item.style.display = "none";
        }, "1000")
      });
    });

    items.forEach(item => {
      const subitems = item.querySelector('div');
      if(subitems != null) {
        item.addEventListener('mouseover', function handleClick(event) {
          subitems.style.display = "flex";
        });

        item.addEventListener('mouseleave', function handleClick(event) {
          setTimeout(() => {
            subitems.style.display = "none";
          }, "1000")
        });
      }
    });
  }
}

Hooks.InitTiny = {
  mounted() {
    tinymce.remove();

    var f = this.el;
    var cancelBtn = document.getElementById("editor-cancel-btn");
    var saveBtn = document.getElementById("editor-save-btn");
    var container = document.getElementById("editor-container");

    var quill = tinymce.init({
      // selector: 'textarea',
      relative_urls : false,
      selector: '#quillEditor',
      // selector: '#quillEditor_'+contenter.id,
      width: 1000,
      height: 450,
      plugins: [
        'advlist', 'autolink', 'link', 'image', 'lists', 'preview', 'anchor',
        'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
        'media', 'table', 'emoticons', 'template'
      ],
      toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
        'forecolor backcolor emoticons',
      menubar: 'edit view insert format tools table',
    });
  },
  updated() {
    console.log("UUU")
  }
}

Hooks.ToggleEditor = {
  mounted() {
    var f = this.el;
    var cancelBtn = document.getElementById("editor-cancel-btn");
    var saveBtn = document.getElementById("editor-save-btn");
    var container = document.getElementById("editor-container");

    f.addEventListener("click", function(event) {
      var contenter = document.getElementById(f.dataset.content);

      saveBtn.dataset.caller = contenter.id;
      tinymce.get('quillEditor').setContent(contenter.value);

      container.classList.remove("hidden");
      container.classList.add("absolute");
    });

    saveBtn.addEventListener("click", function(event) {
      container.classList.remove("absolute");
      container.classList.add("hidden");

      var contenter = document.getElementById(saveBtn.dataset.caller);
      contenter.value = tinymce.get('quillEditor').getContent();
    });

    cancelBtn.addEventListener("click", function(event) {
      container.classList.remove("absolute");
      container.classList.add("hidden");
    });
  },
  updated() {
    console.log("UUU")
  }
}

function checkHiddenInPrint(child) {
  let node = child.parentNode;

  while (node != null) {
    if (node.classList != undefined && node.classList.contains('print:hidden')) {
      return true;
    }
    node = node.parentNode;
  }
 return false;
}

Hooks.PrintVersion = {
  mounted() {
    var f = this.el;

    f.addEventListener("click", function(event) {
      console.log("PRINT");
      var footnotes = document.getElementById("footnotes");

      if (footnotes.innerHTML.indexOf("Source") === -1) {
        let p = document.createElement("p")
        footnotes.append("Source URL: "+window.location, p);

        var articleLinks = document.getElementById('content-container').getElementsByTagName('a');

        var footnotesLinks = document.createElement("ol");
        var k = 0;
        for (let i = 0; i < articleLinks.length; i++) {
          if(!checkHiddenInPrint(articleLinks[i])) {
            k++;
            articleLinks[i].innerHTML += " <sub class='print:inline hidden'>["+(k)+"]</sub>";
            var footnote = document.createElement("li");
            footnote.innerText = articleLinks[i].href;
            footnotesLinks.appendChild(footnote);
          }
         }
        footnotes.appendChild(footnotesLinks);

      }

      print();
    });
  }
}


Hooks.Slider = {
  mounted() {
    var slider = document.getElementById("slider")
    var sliderPrev = document.getElementById("slider-prev")
    var sliderNext = document.getElementById("slider-next")
    var currentSlide = document.querySelector("#slider .block")
    var slides = document.querySelectorAll("#slider .slide")
    var currentIndex = Array.prototype.indexOf.call(slides, currentSlide)
    var numberOfSlide = slides.length

    setInterval(nextSlide, 8000)

    function nextSlide() {
      currentSlide = document.querySelector("#slider .block")
      currentIndex = Array.prototype.indexOf.call(slides, currentSlide)
      currentIndex++
      if(currentIndex >= numberOfSlide) {
        currentIndex = 0
      }

      for (var i = 0; i < numberOfSlide; i++) {
        slides[i].classList.remove('block')
        slides[i].classList.add('hidden')
      }

      var slide = slides[currentIndex]
      slide.classList.add('block')
      slide.classList.remove('hidden')
    }
  }
}

Hooks.CopyText = {
  mounted() {
    var f = this.el;
    console.log(f)
    // const copyButtons = document.querySelectorAll('.copy-document-link');

    // copyButtons.forEach(item => {
      // item.addEventListener('click', function handleClick(event) {
      f.addEventListener('click', function handleClick(event) {
        var text = item.dataset.link;
        console.log(text);
        navigator.clipboard.writeText(text).then(function() {
          item.innerText = "Copied";
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      });
    // });


  }
}

let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {params: {_csrf_token: csrfToken}, hooks: Hooks})

// Show progress bar on live navigation and form submits
topbar.config({barColors: {0: "#29d"}, shadowColor: "rgba(0, 0, 0, .3)"})
window.addEventListener("phx:page-loading-start", info => topbar.show())
window.addEventListener("phx:page-loading-stop", info => topbar.hide())

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
// >> liveSocket.disableLatencySim()
window.liveSocket = liveSocket

